import React, { useState, useEffect } from "react";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Particles from "react-tsparticles";
import ReactTypingEffect from "react-typing-effect";

function Contact() {
  const [userContactData, setUserContactData] = useState(null);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    setUserContactData({
      name: "",
      email: "",
      subject: "",
      message: "",
      phone: "",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    //fetch(`http://127.0.0.1:8080/api/k0rd1k`, {
    fetch(`https://petro-store-server.herokuapp.com/api/k0rd1k`, {
      method: "POST",
      body: JSON.stringify(userContactData),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((msg) => {
        handleShow(msg);
        //if (msg) console.log(msg);
      });
  };
  const handleClose = () => {
    setUserContactData({
      name: "",
      email: "",
      subject: "",
      message: "",
      phone: "",
    });
    setShow(false);
    //props.history.goBack(); redirect to previous page
  };
  const handleShow = (msg) => {
    setModalMessage(msg);
    setShow(true);
  };

  const handleChange = (e) => {
    let target = e.currentTarget; // the element that initiated the event
    let value = target.value; // its value
    let name = target.name; // its name
    let newUserData = { ...userContactData }; // preform a "shallow" clone of productData
    newUserData[name] = value; // update the associated property for the control
    setUserContactData(newUserData); // set the new product data
  };

  return (
    <div className="contact sections" id="contact">
      <h1 className="contact-title">CONTACT ME</h1>

      <Container fluid="xxl">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Report:</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Form onSubmit={handleSubmit}>
          <div className="contact-form-div">
            <Row xs={1} sm={2}>
              <Col>
                <InputGroup className="mb-3">
                  <FormControl
                    className="shadow-none"
                    placeholder="Name*"
                    name="name"
                    required
                    value={userContactData ? userContactData.name : ""}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3 shadow-none">
                  <FormControl
                    className="shadow-none"
                    placeholder="Phone"
                    name="phone"
                    value={userContactData ? userContactData.phone : ""}
                    aria-label="Username"
                    onChange={handleChange}
                  />
                </InputGroup>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    className="shadow-none"
                    type="email"
                    name="email"
                    required
                    value={userContactData ? userContactData.email : ""}
                    placeholder="Enter email*"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <FormControl
                    className="shadow-none"
                    placeholder="Subject"
                    aria-label="Subject"
                    name="subject"
                    value={userContactData ? userContactData.subject : ""}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <FormControl
                    className="shadow-none form-textarea"
                    placeholder="Your message here*"
                    as="textarea"
                    name="message"
                    required
                    aria-label="With textarea"
                    value={userContactData ? userContactData.message : ""}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>
          <Button variant="warning" type="submit">
            Submit
          </Button>
        </Form>

        <div className="contact-typingEffect-div">
          <ReactTypingEffect
            className="contact-typingEffect"
            speed={100}
            eraseSpeed={100}
            eraseDelay={5000}
            typingDelay={500}
            cursor=" "
            text={[
              "Let's do it...",
              "Let's Create...",
              "Let's Build...",
              "Let's Improve...",
              "Let's help...",
            ]}
          />
        </div>
      </Container>
      {/* <Particles
        id="tsparticles"
        options={{
          background: {
            color: {},
          },
          fpsLimit: 144,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              repulse: {
                distance: 200,
                duration: 1,
              },
            },
          },
          particles: {
            color: {
              value: "rgb(255,160,0)",
            },
            links: {
              color: "#ffffff",
              distance: 100,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
          },
          detectRetina: true,
        }}
      /> */}
    </div>
  );
}

export default Contact;

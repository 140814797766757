import React from "react";
import "./Home.css";
import ReactTypingEffect from "react-typing-effect";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import Particles from "react-tsparticles";

function Home() {
  return (
    <div className="home sections" id="home">
      <Particles
        id="tsparticles"
        options={{
          fpsLimit: 144,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              repulse: {
                distance: 200,
                duration: 1,
              },
            },
          },
          particles: {
            color: {
              value: "rgb(255,160,0)",
            },
            links: {
              color: "#ffffff",
              distance: 100,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
          },
          detectRetina: true,
        }}
      />
      <div className="home_title">
        Hello, I'm <span className="home_name">Dmytro Kordik</span>
        <br></br> I'm full-stack web developer
      </div>
      <ReactTypingEffect
        className="typingEffect"
        speed={200}
        eraseSpeed={100}
        eraseDelay={900}
        typingDelay={500}
        cursor=""
        text={[
          "Responsive websites",
          "Design + thinking",
          "Websites that work",
          "Building a business online",
          "Results-driven websites",
          "Vision for detail",
          "Websites you can manage",
          "Thinking forward",
          "Websites for everyone",
          "I focus on the details",
          "Intelligent websites",
          "Strategic digital solutions",
          "Newest Technology",
          "Web Development",
        ]}
      />
      <Button variant="outline-warning" href="#contact">
        CONTACT ME
      </Button>{" "}
    </div>
  );
}

export default Home;

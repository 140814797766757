import React from "react";
import "./About.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Photo from "./photo.jpg";

function About() {
  return (
    <div className="about sections" id="about">
      <Container>
        {" "}
        <Row xs={1} sm={2}>
          <Col>
            <div className="about-photo">
              <Image fluid thumbnail src={Photo}></Image>
            </div>
            <p className="about-p">
              <b>Education:</b>
            </p>
            <p className="about-p">
              Sep 2018 - Jan 2020
              <br></br> College diploma. Computer Programmer. Seneca College.
            </p>
            <p className="about-p">
              2005 - Jan 2009
              <br></br> Bachelor’s degree. Electrical Engineering. Ukraine, East
              Ukrainian University.
            </p>
            <p className="about-p">
              <b>Experience:</b>
            </p>
            <p className="about-p">
              Apr 2021 - present
              <br></br> Freelance Full Stack Developer<br></br>
              Currently working at{" "}
              <a
                href="https://uniwelltech.ca/"
                target="_blank"
                rel="noreferrer"
              >
                Uniwell Technologies Inc
              </a>
              .
            </p>
            <p className="about-p">
              Jan 2021 - Mar 2021
              <br></br> Full Stack Developer at{" "}
              <a
                href="https://www.foodpackages.ca/"
                target="_blank"
                rel="noreferrer"
              >
                Food Link Delivery LTD
              </a>
              .
            </p>
          </Col>
          <Col>
            <h3>About</h3>
            <div>
              <p className="about-p">
                &nbsp;&nbsp;&nbsp;&nbsp;Energetic full-stack developer with over
                2 years of working experience; Seneca College graduate with a
                passion to solve problems. Interested in IT, cryptocurrency, and
                AI. Lifetime learner with the ability to grasp new technical
                concepts and utilize them to achieve project objectives.
              </p>
            </div>
            <div className="about-key-skills">
              <div className="about-p">
                <h6>Key skills:</h6> • JavaScript, TypeScript
                <br></br>• Angular, React (Redux)
                <br></br>• Angular Material, Bootstrap
                <br></br>• HTML5, CSS3, SASS/SCSS, DOM
                <br></br>• Node.js (Express.js)
                <br></br>• MongoDB, Mongoose.js, Oracle SQL
                <br></br>• RESTful API
                <br></br>• Docker, CI/CD
                <br></br>• Git, Heroku, GitHub, Netlify
                <br></br>• Firebase, AWS, Stripe
                <br></br>• AJAX, JSON, GeoJSON, Leaflet library
                <br></br>• Socket.io
                <br></br>• Bcrypt.js, jQuery
              </div>
              <div className="about-p">
                <h6>Additional skills:</h6>C, C++, Java, OOP, HTTP protocols,
                Unix<br></br>
                <br></br>
                <h6>Soft skills:</h6>• Problem-solving
                <br></br>• Open-mindedness
                <br></br>• Self-learning
                <br></br>• Detail-oriented
                <br></br>• Creativity and willingness to learn
                <br></br>• Teamwork
              </div>
            </div>
            <div className="about-p">
              <h6>Key Qualifications, Responsibilities &amp; Achievements:</h6>•
              Programming experience with JavaScript and TypeScript.
              <br></br> • Experience working with modern JavaScript frameworks
              such as React and Angular.js, as well as Node.js.
              <br></br>• Experience with Relational and Non-Relational Databases
              such as MySQL and MongoDB.
              <br></br>• Experience working on Unix/Linux OS.
              <br></br>• Experience in hand-coded, standards-compliant HTML5,
              CSS3.
              <br></br>• Knowledge and application of Object-Oriented design and
              development principles.
              <br></br>• Recognize and apply object-oriented concepts like
              encapsulation, polymorphism and inheritance
              <br></br>• Programming experience with Java and C++.
              <br></br>• Provided 1-on-1 technical support via email, phone, and
              in-person.
              <br></br>• Worked both individually and in a team environment.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;

import React from "react";
import "./Portfolio.css";
import AmazonCloneImg from "./amazone-cloneImg.jpg";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import ClientOne from "./clientOne.jpg";
import ClientTwo from "./clientTwo.jpg";
import ClientThree from "./clientThree.jpg";
import FoodpackagesImg from "./foodpackagesImg.jpg";
import BlogImg from "./blogImg.jpg";
import UniwellImg from "./uniwell.png";
import RentarioImg from "./rentarioImg.jpg";
import ProjectCard from "../project-card/ProjectCard";

import Overlay from "react-bootstrap/Overlay";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
function Portfolio() {
  const projects = [
    {
      title: "Amazone Clone",
      img: AmazonCloneImg,
      text: [
        `This is a Full-Stack Amazon Clone with Full E-Commerce Functionality.`,
        `In this project, I used Google Firebase
      and Stripe Payment Processing.`,
        `Thus, here you have the ability to create a user, create an order and pay for it.`,
        `For checking the payment functionality you can use any 
      Test Card number(google it)`,
      ],
      link: "https://clone-72fc6.firebaseapp.com/",
      tooltip: [
        `JavaScript`,
        `React JS`,
        `JSX`,
        `Google Firebase`,
        `Stripe`,
        `Moment JS`,
        `Node.js`,
        `Express.js`,
      ],
    },
    {
      title: "FoodLink",
      img: FoodpackagesImg,
      text: [
        `I designed and developed this fully functional website for food packaging wholesale for my client.`,
        `Customers can browse and filter products, create and send the orders to the sales manager.`,
        `In this project, I used Heroku to deploy the app and MongoDB as a database.`,
        `I also developed a user-friendly admin cabinet where the admin can manage the website in many different ways.`,
      ],
      link: "https://www.foodpackages.ca/",
      tooltip: [
        `JavaScript`,
        `React JS`,
        `React Bootstrap`,
        `JSX`,
        `MongoDB`,
        `Mongoose`,
        `Heroku`,
        `SendGrid`,
        `Node.js`,
        `Express.js`,
      ],
    },
    {
      title: "Uniwelltech",
      img: UniwellImg,
      text: [
        `I worked as a full-stack developer on this website`,

        `This website showcases the innovative products and services offered by UniwellTech.`,
        `The website provides a user-friendly interface for customers to explore and learn more about UniwellTech's cutting-edge technology solutions.`,
        `The website also integrates a MySQL database for efficient data storage and retrieval.`,
        `Overall, my contribution to this project involved building a responsive and visually appealing website with seamless functionality and a robust tech stack.`,
      ],
      link: "https://uniwelltech.ca/",
      tooltip: [
        `JavaScript`,
        `React JS`,
        `React Bootstrap`,
        `TypeScript`,
        `MySQL`,
        `SendGrid`,
        `Node.js`,
        `Express.js`,
      ],
    },
    // {
    //   title: "Angular Blog",
    //   img: BlogImg,
    //   text: [
    //     `This Blog app is my college project. The task was: `,

    //     `To create an Angular app where you can post blogs.`,
    //     `Develop and publish back-end API to manage the blog posts in MongoDB Atlas.`,
    //     `Add functionality to create new posts as well as edit / delete existing posts,
    //     allow users to comment on posts, and track the number of views per post.`,
    //   ],
    //   link: "https://web-422-angular-blog.herokuapp.com/home",
    //   tooltip: [
    //     `JavaScript`,
    //     `AngularJS`,
    //     `Angular Bootstrap`,
    //     `TypeScript`,
    //     `MongoDB`,
    //     `Mongoose`,
    //     `Heroku`,
    //     `Node.js`,
    //     `Express.js`,
    //   ],
    // },
    // {
    //   title: "Comming Soon",
    //   img: FoodpackagesImg,
    //   text: "Comming Soon This is a longer card with supporting text below as a natural longer.",
    //   link: "https://clone-72fc6.web.app/",
    //   tooltip: `Comming Soon Simple tooltip longer card with supporting This is a longer card with
    //   supporting Simple tooltip longer card with supporting This is a longer
    //   card with supporting Simple tooltip longer card with supporting This is a`,
    // },
  ];
  return (
    <div className="portfolio sections" id="portfolio">
      {/* <ProjectCard /> */}
      {/* <div className="portfolio-upper-div">
        <div className="portfolio-tiltle">
          <h1>Projects</h1>
        </div>
        <div className="porfolio-stars">
          {projects.map((project) => (
            <div key={project.title} className="portfolio-star"></div>
          ))}
        </div>
      </div> */}

      <Container>
        <div className="portfolio-tiltle portfolio-tiltle_responsive_off">
          <h1>Projects</h1>
        </div>
        <div className="portfolio-projects">
          <div className="portfolio-tiltle portfolio-tiltle_responsive_on">
            <h1>Projects</h1>
          </div>
          {projects.map((project) => (
            <OverlayTrigger
              key={project.title}
              placement="auto"
              delay={{ show: 900, hide: 100 }}
              overlay={
                <Tooltip id={`tooltip-${project.title}`}>
                  <h6 className="card-tooltip-header">Technology Used:</h6>
                  {project.tooltip.map((text) => (
                    <p>{text}</p>
                  ))}
                </Tooltip>
              }
            >
              <Card className="bg-dark text-white">
                <Card.Img src={project.img} alt="Card image" />

                <Card.Body>
                  <Card.Title>{project.title}</Card.Title>
                  <Card.Text>
                    {project.text.map((paragraph, inx) => (
                      <p key={inx} className="card-text-paragraph">
                        {paragraph}
                      </p>
                    ))}
                  </Card.Text>
                  <Card.Link href={project.link} target="_blank">
                    Link to project
                  </Card.Link>
                </Card.Body>
              </Card>
            </OverlayTrigger>
          ))}
        </div>

        {/* <div className="portfolio-clients">
          <div className="portfolio-carousel">
            <Carousel>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={ClientOne}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>My happy client Peter</h1>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={ClientTwo}
                  alt="Second slide"
                />
                <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={ClientThree}
                  alt="Third slide"
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div> */}
      </Container>
    </div>
  );
}

export default Portfolio;
